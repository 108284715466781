import React from "react";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Posts from "components/Posts";
import Interaction from "components/Interaction";

const InteractionPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <Interaction />
    </Layout>
  );
};

export default InteractionPage;
